@charset "UTF-8";

.block {
  display: block;
}

.width-100 {
  width: 100% !important;
}


.width-80 {
  width: 80% !important;
}

.width-70 {
  width: 70% !important;
}

.height-100 {
  height: 100% !important;
}

.height-140px {
  height: 140px !important;
}

.width-50 {
  width: 50% !important;
}

.width-15 {
  width: 15% !important;
}

.rmp {
  padding: 0 !important;
  margin: 0;
}

.force-rmp {
  padding: 0 !important;
  margin: 0 !important;
}

.opacity-50 {
  opacity: .5;
}

.no:before {
  content: 'N° ';
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  padding: 0px !important;
  -webkit-appearance: none;
  background-color: #FAFAFA;
  background-color: #FFF;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .3);
}

input[type="checkbox"],
input[type="checkbox"] + label {
  cursor: pointer;
}

label > input[type="checkbox"].toggle-btn {
  position: relative;
  top: 6px;
  margin-left: 6px;
}

input[type="checkbox"]:not(.toggle-btn):after {
  content: '';
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  top: 49.5%;
  left: 49.5%;
  border-radius: 50%;
  background-color: transparent;
  transition: all .2s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0s;
}
input[type="checkbox"]:not(.toggle-btn):checked:after {
  width: 60%;
  height: 60%;
  top: 20%;
  left: 20%;
  background-color: #16BAFF;
  transition: all .2s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0s;
}

input[type="checkbox"].toggle-btn {
  background-color: #DDD;
  width: 35px;
  height: 22px;
  border-radius: 20px;
  box-shadow: none;
}

input[type="checkbox"]:checked.toggle-btn {
  background-color: rgba(31, 14, 62, 0.9);
}

input[type="checkbox"].toggle-btn:before {
  content: '';
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  top: 1px;
  left: 1px;
  border-radius: 50%;
  background-color: #FFF;
  transform: translateX(0);
  transition: all .2s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0s;
}

input[type="checkbox"]:checked.toggle-btn:before {
  background-color: #FFF;
  transform: translateX(13px);
  transition: all .2s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.text-red {
  color: #BB0000;
}
.text-green {
  color: #4CAF50 !important;
}
.text-grey {
  color: #6C6C6C !important;
}
.text-blue {
  color: #2196F3 !important;
}
.text-cyan {
  color: #00D5FF !important;
}
.text-violet {
  color: #1F0E3E !important;
}
.text-white {
  color: #FFF !important;
}

.compact-children-margin > *:first-child {
  margin-left: 0;
}
.compact-children-margin > *:last-child {
  margin-right: 0;
}

.compact-children-padding > *:first-child {
  padding-left: 0;
}
.compact-children-padding > *:last-child {
  padding-right: 0;
}

.inline-block {
  display: inline-block;
}

.align-left {
  float: left;
}
.align-right {
  float: right;
}

button,
button:hover,
button:active,
button:focus,
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  box-shadow: none;
  outline: none;
  border: none;
}

* {
  box-sizing: border-box;
  outline: none;
}

/**:not(input),
*:not(select),
*:not(textarea) {
  user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}*/

.flexible {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-just-bet {
  justify-content: space-between !important;
}

.flex-just-center {
  justify-content: center !important;
}

.flex-al-end {
  align-self: flex-end;
}

.inline-flexible {
  display: inline-flex !important;
}

.flexible.flexible-row {
  flex-direction: row;
}

.flexible.flexible-column {
  flex-direction: column;
}

.flexible > * {
  align-self: center;
}

.blured-15 {
  -webkit-filter: blur(15px);
  -moz-filter: blur(15px);
  -ms-filter: blur(15px);
  -o-filter: blur(15px);
  filter: blur(15px);
}

ol,
ul {
  list-style: none;
  padding: 0;
}

.text-caps {
  font-variant: small-caps;
}

.text-italic {
  font-style: italic;
}
.text-center {
  text-align: center;
  justify-content: center;
  align-self: center;
}
.text-right {
  text-align: right;
  justify-content: flex-end;
}
.text-left {
  text-align: left !important;
  justify-content: flex-start;
}
.text-uppercase {
  text-transform: uppercase;
}
.price {
  margin-right: 10px;
}
.price.cur-usd:after {
  content: '$';
}
.price.cur-euro:after {
  content: '€';
}
.img-wrapper {
  overflow: hidden;
  display: inline-block;
}
.paddings {
  padding: 50px 0;
}
.wrapper,
.list-wrapper {
  overflow: hidden;
  margin: 17px 0;
}
.list-wrapper > ul {
  margin: 0;
}
.bg-bluegrey {
  background: #3A5A75;
}
.bg-blue {
  background-color: #2196F3 !important;
}
.bg-cyan {
  background-color: #00D5FF !important;
}
.bg-dark-blue {
  background-color: var(--footer-bgColor);
}

.column-150 {
  min-height: 150px !important;
}

.column-1501 {
  height: 150px !important;
}

.column-350 {
  height: 350px !important;
}
.column-150 > .img-wrapper {
  min-width: 150px;
  max-width: 250px;
}
.column-350 > .img-wrapper {
  min-width: 150px;
  max-width: 200px;
}
.column-350 > .img-wrapper img {
  max-height: 150px;
}
.no-border {
  border: none !important;
}
.override-container {
  max-width: unset;
  width: initial;
}
.input-in-table {
  padding: 15px 20px;
  box-shadow: none !important;
  font-family: 'TitilliumWeb-SemiBold';
  color: #666;
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent;
  -webkit-appearance: none;
  transition: all .2s ease-out 0s;
}
.input-in-table:focus,
.input-in-table:hover {
  box-shadow: 0 0 10px 0 #BDBDBD !important;
  transition: all .2s ease-out 0s;
}
.no-bg {
  background-color: transparent !important;
  color: #333;
}
.no-bg > * {
  color: inherit !important;
}
.no-border-right {
  border-right: none !important;
}
.column.contain-textarea {
  min-height: 200px;
}
.align-self-flexstart {
  align-self: flex-start !important;
}
.border-top {
  border-top: 1px solid #E0E0E0 !important;
}
.text-bold {
  font-family: 'TitilliumWeb-Bold';
}
.text-regular {
  font-family: 'TitilliumWeb-Regular';
}

.ngx-datatable {
  overflow: unset!important;
}


.pointer-t {
  cursor: pointer!important;
}
