@charset "UTF-8";

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: url('fonts/Lato-Regular.eot');
  src: local('Lato-Regular.woff2'), 
      url('fonts/Lato-Regular.woff2') format('woff2'),
      url('fonts/Lato-Regular.woff') format('woff'),
      url('fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Quantify';
  font-weight: 700;
  src: url('fonts/Quantify-Bold.eot');
  src: local('Quantify-Bold.woff2'),
    url('fonts/Quantify-Bold.woff2') format('woff2'),
    url('fonts/Quantify-Bold.woff') format('woff'),
    url('fonts/Quantify-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'TitilliumWeb-Regular';
  font-weight: 400;
  src: url('fonts/TitilliumWeb-Regular.eot');
  src: local('TitilliumWeb-Regular.woff2'),
    url('fonts/TitilliumWeb-Regular.woff2') format('woff2'),
    url('fonts/TitilliumWeb-Regular.woff') format('woff'),
    url('fonts/TitilliumWeb-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'TitilliumWeb-SemiBold';
  font-weight: 500;
  src: url('fonts/TitilliumWeb-SemiBold.eot');
  src: local('TitilliumWeb-SemiBold.woff2'),
    url('fonts/TitilliumWeb-SemiBold.woff2') format('woff2'),
    url('fonts/TitilliumWeb-SemiBold.woff') format('woff'),
    url('fonts/TitilliumWeb-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'TitilliumWeb-Bold';
  font-weight: 700;
  src: url('fonts/TitilliumWeb-Bold.eot');
  src: local('TitilliumWeb-Bold.woff2'),
    url('fonts/TitilliumWeb-Bold.woff2') format('woff2'),
    url('fonts/TitilliumWeb-Bold.woff') format('woff'),
    url('fonts/TitilliumWeb-Bold.ttf') format('truetype');
}
