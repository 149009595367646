@charset "UTF-8";

/*@import '~@swimlane/ngx-datatable/release/index.css';
@import '~@swimlane/ngx-datatable/release/themes/material.css';
@import '~@swimlane/ngx-datatable/release/assets/icons.css';*/

:root {
  --btn-bgColor-dark:#200E3E;
  --btn-bgColor: #16BAFF;
  --btn-bgColor-darker: #0C9AD6;
  --header-bgColor: #1F0E3E;
  --footer-bgColor: #1F0E3E;
  --grey-color: #6C6C6C;
  --grey-color-darker: #424242;
  --subfooter-bgColor: #0F071E;
}

*,
*:before,
*:after{
  box-sizing: border-box;
}

html, body{
  height: 100%;
  margin: 0;
}

body {
  font-family: 'TitilliumWeb-SemiBold';
  overflow-x: hidden;
}

footer a:focus, footer a:hover {
    color: #16baff;
}

.btn:not(.link-style) {
  cursor: pointer;
  background-color: var(--btn-bgColor);
  color: #FFF;
  border-radius: 3px;
  font-family: TitilliumWeb-SemiBold;
  text-transform: uppercase;
  text-align: center;
  min-width: 220px;
  padding: 10px 0;
  display: inline-block;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2);
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transition: all .2s ease-out 0s;
}
.btn.dark {
  background-color: var(--btn-bgColor-dark) !important;
}


.btn:not(.link-style):hover {
  border-top: 3px solid var(--btn-bgColor);
  border-bottom: 3px solid transparent;
  background-color: var(--btn-bgColor-darker);
  box-shadow: 0 3px 10px 3px rgba(0, 0, 0, .2);
  transition: all .2s ease-out 0s;
}

button.btn:not(.link-style),
.btn.btn-style {
  background-color: #FFF;
  color: var(--btn-bgColor);
  box-shadow: 0 0 3px 0 var(--btn-bgColor);
}

button.btn.btn-primary:not(.link-style) {
  background-color: var(--btn-bgColor);
  color: #FFF;
}
button.btn:not(.link-style):hover,
button.btn.btn-primary:not(.link-style):hover,
.btn.btn-style:hover {
  border-top: 3px solid var(--btn-bgColor);
  background-color: var(--btn-bgColor-darker);
  color: #FFF;
  box-shadow: 0 0 3px 0 var(--btn-bgColor);
}

.link-style {
  background-color: transparent;
  outline: none;
  font-family: 'TitilliumWeb-SemiBold';
  color: var(--header-bgColor);
  padding: 3px 0;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: all .2s ease-out 0s;
}

.link-style:hover {
  border-bottom: 2px solid var(--header-bgColor);
  transition: all .2s ease-out 0s;
}

.btn-grey {
  background-color: #FFF;
  color: var(--grey-color) !important;
  box-shadow: 0 0 3px 0 var(--grey-color) !important;
}

.btn-grey:hover {
  border-top: 3px solid var(--grey-color) !important;
  background-color: var(--grey-color-darker) !important;
  color: #FFF !important;
  box-shadow: 0 0 3px 0 var(--grey-color) !important;
}

.btn-green {
  background-color: #4CAF50;
  color: #FFF !important;
  box-shadow: 0 0 3px 0 #4CAF50 !important;
}

.btn-green:hover {
  border-top: 3px solid #4CAF50 !important;
  background-color: #388e3c !important;
  color: #FFF !important;
  box-shadow: 0 0 3px 0 #4CAF50 !important;
}

nav#header {
  font-family: 'TitilliumWeb-SemiBold';
  background-color: var(--header-bgColor)!important;
  /*padding: 15px 0;*/
  position: relative;
  /*height: 85px; keep in auto */
  z-index: 999;
  align-items: center;
}

.navbar-toggler.navbar-toggler-right{
  color: var(--btn-bgColor);
  width: 50px;
  height: 50px;
  margin: 0;
  z-index: 999;
}

.navbar {
  text-align: center;
}

.nav-item.active {
  color: var(--btn-bgColor) !important;
  font-family: 'TitilliumWeb-Bold';
}

nav#header .logo-wrapper img {
  width: 150px;
}

.nav-item:hover {
  border-bottom: 1px solid #FFF;
  color: #FFF;
  transition: all .2s ease-out 0s;
}

a.nav-item {
  margin: 0 15px;
  color: #E0E0E0;
  border-bottom: 1px solid transparent;
  transition: all .2s ease-out 0s;
}

#banner {
  position: relative;
}

#banner.for-usermenucontent,
#banner.for-booking {
  height: 250px;
  overflow: hidden;
}

#banner.for-booking img {
  transform: translateY(-30%);
}

#banner.for-usermenucontent header,
#banner.for-booking header {
  padding: 30px 130px;
  color: #FFF;
  font-family: 'TitilliumWeb-Bold';
}

#banner.for-booking .overlay {
  justify-content: flex-start;
}

.travel {
  padding: 30px 0;
  text-align: center;
  margin: 30px 0;
}

.breakpoints-list {
  display: inline-flex;
  width: 100%;
  margin: 0;
  justify-content: space-between;
}

.breakpoints-list li {
  width: 60px;
  height: 60px;
  background: #E0E0E0;
  color: var(--grey-color);
  border-radius: 50%;
  text-align: center;
  position: relative;
  transition: all .2s ease-out 0s;
}

.breakpoints-list li label {
  position: absolute;
  left: 0;
  width: calc(60px * 3);
  font-family: 'TitilliumWeb-SemiBold';
  font-size: 1rem;
  line-height: 1.2rem;
  transform: translateX(-60px) translateY(10px);
  transition: all .2s ease-out 0s;
}

.breakpoints-list li.current,
.breakpoints-list li.passed {
  background: var(--header-bgColor);
  color: var(--btn-bgColor);
  transition: all .2s ease-out 0s;
}

.breakpoints-list li:not(:last-child):after {
  content: '';
  position: absolute;
  left: 0;
  display: flex;
  flex: 1;
  width: 0;
  height: 5px;
  background-color: var(--header-bgColor);
  z-index: 0;
  transform: translateX(60px) translateY(-33px);
  transition: all .3s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0s;
}

.breakpoints-list li.passed:not(:last-child):after {
  width: 500%;
  transition: all .3s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0s;
}

.breakpoints-listwithHousing li.passed:not(:last-child):after {
  width: 419%!important;
}

.breakpoints-listwithHousing li:not(:first-child):before {
  width: 419%!important;
}

.breakpoints-list li:not(:first-child):before {
  content: '';
  position: absolute;
  right: 0;
  display: flex;
  flex: 1;
  width: 500%;
  height: 5px;
  background-color: #E0E0E0;
  z-index: -1;
  transform: translateX(-60px) translateY(27px);
}

.breakpoints-list li a {
  display: inline-block;
  font-size: 1.5rem;
  padding: 12px 0;
  width: 100%;
  height: 100%;
  color: inherit;
}

.time-remaining {
  padding: 30px 0;
  margin: 30px 0;
  text-align: center;
}

.time-remaining .time i {
  font-size: 1.3rem;
}

.time-remaining .time h3 {
  font-size: 2.5rem;
  font-family: 'TitilliumWeb-Bold';
  color: var(--header-bgColor);
}

.time-remaining .infos {
  margin-top: 10px;
}

.time-remaining .infos span:nth-child(1) {
  font-family: 'TitilliumWeb-Regular';
  font-size: 1.3rem;
  color: var(--header-bgColor);
}

.time-remaining .infos span:nth-child(2) {
  font-family: 'TitilliumWeb-Regular';
  font-size: .75rem;
  color: var(--header-bgColor);
  opacity: .5;
}

#monprofil-content,
#mesbillets-content,
.details-cmd {
  /*padding: 30px 0;
  padding-top: 60px;
  margin: 0;*/
 /* border-top: 1px solid #EEEEEE;*/
}

#monprofil-content header,
#mesbillets-content header,
.details-cmd header {
  background-color: var(--header-bgColor);
  padding: 20px;
  justify-content: space-between;
  border-radius: 2px;
}

#monprofil-content header h5,
#mesbillets-content header h5,
.details-cmd header h5 {
  color: #FFF;
  font-family: 'TitilliumWeb-Bold';
}

#monprofil-content header a,
#mesbillets-content header a,
.details-cmd header a {
  padding: 5px 0;
  text-align: center;
  width: 30px;
  height: 30px;
  color: #FFF;
}

#monprofil-content .table-wrapper,
#mesbillets-content .table-wrapper,
.details-cmd .table-wrapper {
  overflow: hidden;
  margin-bottom: 50px;
}

.details-cmd .table-row {
  /* background-color: #FAFAFA; */
}

.table .price {
  margin: 0;
}
.width-10px{
  width: 100px;
}
.table-row {
  border: 1px solid #E0E0E0;
}
.table-row:not(:last-child) {
  border-bottom: none;
}
.border-bottom-1 {
  border-bottom: 1px solid #E0E0E0;
}
.table-row:first-child {
  border-top: none;
}
.table-row.flexible {
  /*flex-wrap: wrap;*/
}
.table-row .line-wrap {
  border-top: 1px solid #E0E0E0;
}
.table-row.has-danger .form-control {
    border: 1px solid #d9534f !important;
}
.table-row .row-error {
  display: none;
}
.table-row.has-danger .row-error {
    display: block;
}


.holders-list {
  margin: 0;
  margin-bottom: 30px;
  border: 1px solid #E0E0E0;
}
.m-top-50 {
  margin-top: 50px;
}
.m-top-10 {
  margin-top: 10px!important;
}
.ft-size-14 {
  font-size: 14px!important;
}
.padding-10 {
  padding: 10px;
}
.padding-t-b-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.holders-list-1 {
  margin: 0;
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0; ;
  width: 100%;
}
.ft-16 {
  font-size: 16px;
}
.linet {
  text-decoration: line-through;
}
.holders-list-1 li {
  margin: -1px 0;
  padding: 5px 21px;
  font-family: 'TitilliumWeb-Regular';
  color: var(--grey-color);
}

.holders-list li {
  margin: 5px 0;
  padding: 5px 15px;
  font-family: 'TitilliumWeb-Regular';
  color: var(--grey-color);
}

.holders-list li span {
  font-family: 'TitilliumWeb-SemiBold';
}


.column_detail {
  padding: 20px;
  height: 60px;
  border-right: 1px solid #E0E0E0;
  font-family: 'TitilliumWeb-SemiBold';
  align-self: center;
}

.column {
  padding-left: 20px;
  padding-right: 20px;
  border-right: 1px solid #E0E0E0;
  font-family: 'TitilliumWeb-SemiBold';
  align-self: center;
}


.column-header {
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'TitilliumWeb-SemiBold';
  align-self: center;
}

.column:last-child {
  border-right: none;
}
.row-bg-color {
  background-color: #F4F2F3;
}
.ft-size-20 {
  font-size: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.ft-size-17 {
  font-size: 17px;
}
.row-padding {
  padding: 7px 14px;
}
.row-pading-content {
  padding: 31px 17px;
}
.bg-color {
 color: var(--btn-bgColor);
}
.index {
  width: 8%;
}
.title {
  width: 70%;
}
.mr-65 {
  margin-right:65px;
}
.total-title {
  width: 180px;
  text-align: left;
}
.has-form {
  justify-content: space-between;
}
.has-form > .column {
  flex: 1;
}
.has-input,
.has-price {
  width: 10%;
}
.recap-items {
  width: 14%;
}
.ft-size-13 {
  font-size: 13px;
}
.margin-auto {
  margin: auto!important;
}
.has-input {
  padding: 10px;
}
.has-input input {
  padding-left: 10px;
  width: 100% !important;
}
.table-header > div {
  height: 60px;
  padding: 20px;
  font-family: 'TitilliumWeb-SemiBold';
  color: var(--grey-color);
}
.table-header i {
  margin-right: 10px;
}
.table-header > div:first-child {
  background-color: #E0E0E0;
}

.addons-wrapper {
  padding: 30px 0;
}

.addons-wrapper header {
  background: transparent;
  border-bottom: 1px solid #E0E0E0;
  border-radius: 0;
  padding: 10px 0;
  font-size: 1.2rem;
  font-family: 'TitilliumWeb-SemiBold';
  color: var(--grey-color);
}

.addons-wrapper .addons-content {
  padding: 30px 0;
  font-family: 'TitilliumWeb-Regular';
  color: var(--grey-color);
}

.addons-wrapper .addons-content label {
  margin-left: 10px;
  margin-bottom: 0;
}

.addons-wrapper .addons-content label a {
  font-family: 'TitilliumWeb-SemiBold';
  color: var(--btn-bgColor);
}

#banner .overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  max-width: unset;
  padding: 0;
  margin: 0;
  background-color: transparent;
  justify-content: center;
  transition: all .25s ease-out 0s;
}

#banner:not(.for-booking):not(.for-usermenucontent) .overlay:hover {
  background-color: rgba(0,0,0,.5);
  transition: all .25s ease-out 0s;
}

#banner:not(.for-booking) .overlay > a {
  font-size: 1.2rem;
  line-height: 2.2rem;
  opacity: 0;
}

#banner:not(.for-booking) .overlay:hover > a {
  opacity: 1;
}

#event-details,
#discover-events,
#upcoming-events {
  padding: 20px 0;
}

#event-details
{

}

#chilevents
{
  padding: 2px 0;
}

#parevents
{
  padding: 2px 0;
}

@media only screen and (min-width: 1024px) {
  #event-details,
  #discover-events,
  #upcoming-events {
    padding: 50px 0;
  }
}



/*@media only screen and (min-width: 1024px) {
  #event-details{
   height: 1300px;
  }
}*/

#upcoming-events ul.list {
  margin-bottom: 50px;
}

#event-details .row.flexible {
  position: relative;
  padding-bottom: 7%;
}

#upcoming-events .btn,
#discover-events .btn {
  min-width: 110px;
  margin-top: 20px;
  padding: 5px 0;
}

header.section-header {
  overflow: hidden;
  padding: 20px 0;
}

header.section-header span.title,
header.section-header i {
  color: var(--header-bgColor);
  font-size: 1.3rem;
  line-height: 1.3rem;
}

header.section-header span.title {
  margin-left: 10px;
  font-family: 'TitilliumWeb-SemiBold';
}


header.section-header span.title222 {
  margin-left: -100px;
  font-family: 'TitilliumWeb-SemiBold';
}

header.section-header ul.actions {
  justify-content: space-between;
  margin: 0;
}

header.section-header ul.actions > li {
  margin: 0 5px;
  display: inline-block;
}

#discover-events header.section-header ul.actions li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #EEE;
  border: 1px solid #BDBDBD;
  text-align: center;
  padding: 3px 0;
}

ul.actions li.disabled {
  pointer-events: none;
  opacity: .5;
}
ul.actions li.disabled i {
  opacity: 0;
}

#discover-events header.section-header ul.actions li a i {
  color: rgba(0, 0, 0, .4);
  font-size: 1rem;
}

header.section-header ul.actions > li.active > a {
  font-family: 'TitilliumWeb-SemiBold';
  border-bottom: 2px solid rgba(31, 14, 62, 0.8);
  color: var(--header-bgColor);
}

header.section-header ul.actions > li > a {
  font-family: 'TitilliumWeb-Regular';
  font-size: .75rem;
  border-bottom: 2px solid transparent;
  color: #6C6C6C;
}

.event,
.section-content {
  overflow: hidden;
}

.event {
  padding: 0 20px;
}

.event > a {
  color: var(--grey-color);
}

.event .evt-details {
  margin-top: 20px;
}

.evt-img-wrapper {
  /* height: 198px;
  background: #eeeeee; */
  overflow: hidden;
}

.evt-details h3,
.evt-details h5 {
  font-family: 'TitilliumWeb-SemiBold';
}

.evt-date {
  color: var(--header-bgColor);
}

.evt-time {
  margin-left: 20px;
  color: var(--grey-color);
}

.evt-time-detail1 {

  color: var(--grey-color);
}

.evt-time-detail {
  margin-left: 5px;
  color: var(--grey-color);
}

.evt-place {
  color: var(--header-bgColor) !important;
}

.evt-location {
  margin-top: 15px;
  color: var(--grey-color);
}

.evt-actionbar {
  justify-content: flex-end;
  position: relative;
  padding-bottom: 20px;
  padding-right: 5px;
}

.evt-actionbar button {
  min-width: 130px;
  margin-bottom: 10px;
}

.evt-infos {
  position: absolute;
  bottom: 0;
  font-family: 'TitilliumWeb-Regular';
  opacity: 1;
  font-weight: 700;
  transition: opacity .2s ease-out 0s;
}

.event:hover .evt-infos {
  opacity: 0;
  transition: opacity .2s ease-out 0s;
}

footer {
  position: relative;
  padding: 60px 0;
  padding-bottom: 0;
  background-color: var(--footer-bgColor);
}

footer .btn {
  width: 100%;
  min-width: unset !important;
}

footer ul {
  flex: 1;
  margin: 0;
}

footer li.footer-col {
  align-self: flex-start;
  padding: 0 50px;
}

footer ul li header {
  display: inline-block;
  color: var(--btn-bgColor);
  font-size: 1rem;
  line-height: 2rem;
  font-family: 'TitilliumWeb-Bold';
}

footer ul li ul {
  margin-top: 10px;
}

footer ul li ul li {
  margin: 2px 0;
  padding: 12px 0;
  font-size: .9rem;
  font-family: 'TitilliumWeb-Regular';
  border-bottom: 1px solid rgba(255, 255, 255, .3);
}

footer ul li ul li a {
  color: rgba(255, 255, 255, .9);
}

footer ul li ul li a i {
  margin-right: 5px;
}

#akces-rest {
  text-align: center;
  border-left: 1px solid rgba(255, 255, 255, .3);
}

#solution-pro {
  text-align: center;
  border-left: 1px solid rgba(255, 255, 255, .3);
}

#solution-pro > span {
  font-family: 'Quantify';
  color: #FFF;
  font-size: 3rem;
}

#solution-pro > p {
  font-family: 'TitilliumWeb-SemiBold';
  color: #FFF;
  font-size: 1rem;
  line-height: 1.5rem;
}

footer .btn {
  min-width: unset;
  width: 100%;
  max-width: 220px;
}

/* changed to .btn only */
a.btn.btn-transparent,
#solution-pro .btn {
  background-color: transparent;
  color: #FFF;
  box-shadow: 0 0 3px 0 var(--btn-bgColor);
}

#akces-rest p {
  color: #FFF;
  font-family: 'TitilliumWeb-SemiBold';
}

#social-nets {
  margin-top: 30px;
}

#social-nets li {
  display: inline-block;
  margin: 0 5px;
  border: none;
}

#social-nets li,
#social-nets li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
}

#social-nets li:hover > a {
  background-color: rgba(255, 255, 255, .5);
}

#social-nets li a {
  display: inline-block;
  text-align: center;
  padding: 10px 0;
  border: 1px solid rgba(255, 255, 255, .1);
}

#social-nets li a i {
  margin: 0;
}

footer .sub-footer {
  margin-top: 50px;
  padding: 35px 0;
  background-color: var(--subfooter-bgColor);
}

footer .sub-footer ul {
  display: inline-block;
}

footer .sub-footer ul li {
  display: inline-block;
  margin: 0 20px;
}

footer .sub-footer ul li a,
footer .sub-footer ul li {
  display: inline-block;
  color: rgba(255, 255, 255, .5);
  font-size: .9rem;
  font-family: 'TitilliumWeb-Regular';
}

footer .sub-footer ul li:hover > a {
  color: #FFF;
}

footer .main {
  overflow: hidden;
}

.scrollToTop-wrapper {
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-50%);
}

header #burger-menu {
  display: none;
}

#event-details header.section-header {
  color: var(--header-bgColor);
  font-family: 'TitilliumWeb-Bold';
  border-bottom: 2px solid var(--grey-color);
}

#event-details header.section-header .evt-time {
  margin-left: 10px;
  font-family: 'TitilliumWeb-SemiBold';
}

#event-details header.section-header .evt-time i {
  font-size: .8rem;
  color: var(--grey-color);
}

#event-details header.section-header .evt-place {
  margin-top: 5px;
  color: var(--grey-color) !important;
  font-family: 'TitilliumWeb-Regular';
}

#event-details header.section-header .evt-place i {
  font-size: .8rem;
  color: var(--grey-color) !important;
}

#event-details .section-content .evt-desc-wrapper p {
  padding: 0;
  max-height: 300px;
  overflow: hidden;
  position: relative;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgba(0, 0, 0, .7);
  transition: max-height .3s ease-out 0s;
}

#event-details .section-content .evt-desc-wrapper {
  overflow: hidden;
}

#event-details .section-content .evt-desc-wrapper p.shawoded:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  background: linear-gradient(transparent, #FFF);
}

a.readMore {
  font-family: 'TitilliumWeb-Bold';
  font-size: .8rem;
  color: rgba(0, 0, 0, .6);
  position: relative;
  top: -10px;
}

#event-details .tabs {
}

.tabs .tabs-list {
  margin: 0;
  padding: 5px 10px;
  justify-content: flex-start;
}

.tabs .tabs-list li {
  min-width: 130px;
  padding: 10px 0;
  margin: 0 5px;
  text-align: center;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, .1);
}

.tabs .tabs-list li.active {
  border-bottom-color: transparent;
  border-top-color: var(--header-bgColor);
  border-top-width: 1px;
}

.tabs .tabs-list li a {
  padding: 5px 10px;
  font-size: .8rem;
  font-family: 'TitilliumWeb-SemiBold';
  color: var(--header-bgColor);
}

.tabs .tabs-content {
  padding: 0;
  position: relative;
  width: 100%;
}

.tabs-content .tab-content {
  flex: 0;
  overflow: hidden;
}

.tabs-content .tab-content.active {
  flex: 1;
}

.tabs .tabs-content .map-wrapper {
  min-height: 400px;
  width: 100%;
  position: relative;
}

.tabs-content > .row {
  margin: 0;
}

.tabs .tabs-content .map-wrapper #map {
  position: absolute;
  background-color: #E0E0E0;
  height: 100%;
  width: 100%;
}

.tabs .tabs-content .map-wrapper #access-desc {
  background-color: rgba(0, 0, 0, .6);
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 35%;
  padding: 15px;
  color: #FFF;
  font-family: 'TitilliumWeb-SemiBold';
}

#reservation-wrapper {
  margin-top: 30px;
 /* position: absolute;*/
  right: 0;
  top: 0;
  flex: 1;
}

#reservation-wrapper .card {
  padding: 0;
  border: none;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 30px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.2);
  background-color: #FAFAFA;
}

.vertical_line{
  height:50px;
  width:1px;
  background:#a0a0a0;
}

.card .card-header {
  padding: 20px;
  background-color: #EEE;
}

.card .card-header header {
  text-transform: capitalize;
  color: var(--header-bgColor);
  font-family: 'TitilliumWeb-SemiBold';
  font-size: 1.5rem;
}

.card-mini {
  box-shadow: none !important;
  background-color: transparent !important;
}

.card-mini .card-header {
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  background-color: transparent;
  padding: 10px 0;
}

.card-mini .card-header header {
  color: var(--grey-color);
  font-family: 'TitilliumWeb-SemiBold';
  font-size: 1rem;
}

.card-mini .card-body {
  padding: 15px 0;
}

.card-mini .card-body .social-nets,
.card-mini .card-body .user-card {
  margin: 0;
}

.user-card .img-wrapper {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.user-card .img-wrapper img {
  height: 100%;
  width: 100%;
}

.user-card header {
  margin-left: 30px;
  font-family: 'TitilliumWeb-SemiBold';
  font-size: 1.2rem;
  color: var(--grey-color);
}

.card-mini .card-body .social-nets li {
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin: 0 10px;
  border-radius: 50%;
}

.card-mini .card-body .social-nets li a,
.card-mini .card-body .social-nets li a img {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.card .card-body > .ticket-purpose,
.card .card-body > .counter-wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.counter-wrapper {
  padding: 50px;
}

.counter-wrapper ul {
  margin: 0;
  justify-content: center;
}

.counter-wrapper ul li {
  width: calc(100px - 15px * 2);
  height: calc(100px - 15px * 2);
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, .2);
  position: relative;
  padding: 15px;
  margin: 0;
  margin-right: 5px;
  box-sizing: content-box;
}

.counter-wrapper ul li:last-child {
  margin: 0;
  margin-right: 0;
}

.counter-wrapper ul li input {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  text-align: center;
  font-family: 'TitilliumWeb-Regular';
  font-size: 3rem;
  color: var(--header-bgColor);
}

.counter-wrapper ul li label {
  font-family: 'TitilliumWeb-SemiBold';
  color: var(--grey-color);
  font-size: .8rem;
  display: inline-block;
  width: 100%;
  text-align: center;
  transform: translateY(150%);
}

.counter-wrapper ul li:before,
.counter-wrapper ul li:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 1px;
  top: 50%;
  z-index: 1;
  background-color: rgba(0, 0, 0, .2);
}

.counter-wrapper ul li:before {
  left: 0;
}
.counter-wrapper ul li:after {
  right: 0;
}

.ticket-purpose {
  padding: 20px;
  font-family: 'TitilliumWeb-SemiBold';
}

.ticket-purpose,
.ticket-used,
.ticket-coming {
  justify-content: space-between;
}
.detailClass {
  color: black;
  font-size: 14px;
  margin-left: 5px;
}

.ticket-purpose header,
.ticket-purpose header h4 {
  align-self: center;
  line-height: 1rem;
  font-size: 1rem;
  color: var(--grey-color);
}

.ticket-purpose header ul li,
.ticket-purpose header p.ticket-desc {
  margin: 0;
  font-family: 'TitilliumWeb-Regular';
  font-size: .8rem;
}
.margin-desc
{
  margin-left: 7px;
  margin-top: 3px;
}

.ticket-purpose .price-wrapper {
  color: var(--grey-color);
  align-self: flex-start;
}

.table input,
.ticket-purpose .price-wrapper input {
  width: 66px;
  height: 35px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 2px;
  outline: none;
  color: var(--grey-color);
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .12) inset;
}

.price-total {
  padding: 20px;
  text-align: right;
  font-family: 'TitilliumWeb-SemiBold';
  font-size: 1.5rem;
  background-color: #FFF !important;
}

#validateCmd {
  padding: 20px;
  background-color: var(--btn-bgColor);
  width: 100%;
  color: #FFF;
  font-family: 'TitilliumWeb-Bold';
  font-size: 1.2rem;
}

#validateCmd i {
  float: right;
  margin-left: 10px;
}

.bg-white {
  background-color: #FFF;
}

.ticket-used {
  background-color: rgba(244, 67, 54, 0.2);
  opacity: .7;
}
.ticket-used header h4 {
  text-decoration: line-through;
}

.ticket-coming {
  background-color: rgba(33, 150, 243, 0.2);
}

.ticket-coming header,
.ticket-coming .price {
  color: rgba(33, 150, 243, 1) !important;
}
.ticket-used header,
.ticket-used .price {
  color: rgba(244, 67, 54, 1) !important;
}

.badge {
  display: inline-block;
  padding: 8px 15px;
  border-radius: 2px;
  color: #FFF;
  font-size: .8rem;
  font-family: 'TitilliumWeb-Bold';
}
.badge-red {
  background-color: rgba(244, 67, 54, 1);
}
.badge-blue {
  background-color: rgba(33, 150, 243, 1);
}

.table input[disabled] {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  cursor: default !important;
  pointer-events: none !important;
  font-weight: bold;
  padding: 0;
  text-align: center !important;
}

.toto .price {
  margin-left: 30px;
  font-size: 1.5rem;
  position: relative;
  top: -2px;
}

.price-2 {
  font-size: 1.5rem;
  position: relative;
  top: -2px;
}

.toto .column:first-child {
  color: var(--grey-color);
  font-size: .8rem !important;
  border-right: 0;
}

.toto .column:first-child > .price {
  font-size: 1rem !important;
  position: relative;
  top: -3px;
}

.toto .column:first-child > .price-2 {
  font-size: 1rem !important;
  position: relative;
  top: -3px;
}

#has-icon-as-placeholder {
  position: relative;
}

#has-icon-as-placeholder input {
  padding-left: 35px;
  padding-top: 2px;
}

#as-placeholder {
  position: absolute;
  left: 20px;
}

#credit-card .container > div {
  background-color: #FAFAFA;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 #BDBDBD;
}

#credit-card .container > div > .table-wrapper {
  padding: 10px 8px;
}

#paiements-methods li {
  margin: 0 10px;
}

#credit-card > .container {
  max-width: 500px;
}

#update-infos {
  font-family: 'TitilliumWeb-SemiBold';
  font-size: .8rem;
  position: relative;
  left: 5px;
}

#update-infos i {
  margin-right: 5px;
}

#credit-card .container > div .addons-wrapper {
  padding: 10px;
}

#credit-card .table-row {
  border: none;
}

#credit-card .addons-wrapper,
#credit-card .addons-content {
  padding: 10px 0;
}

a.readMore:hover {
  color: rgba(0, 0, 0, .8);
}

#booking-validation i.check {
  font-size: 10rem;
}

#booking-validation {
  padding: 50px 0;
  color: var(--header-bgColor);
  font-family: 'TitilliumWeb-Regular';
}

#booking-validation h2 {
  margin-bottom: 20px;
}

/* replaced to div role /!\ important */
div[role="backoffice-index"]  {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

div[role="backoffice-events"]  {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

div[role="backoffice-index"]:before,
div[role="backoffice-index"]:after {
  display: none;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 45px;
  background-color: var(--header-bgColor);
}

/* login */
div[role="backoffice-index"]:after {
  bottom: 0;
  top: unset;
}

/*#evt-details-wrapper,
#auth-wrapper {
  align-self: center;
  width: 500px;
  padding: 100px 0;
}*/

#evt-details-wrapper {
  width: 100%;
}

/*#auth-wrapper .form-wrapper {
  margin-top: 50px;
}

#auth-wrapper .form-group {
  width: 100%;
  margin-bottom: 0;
}*/

#auth-wrapper input:not([type="checkbox"]) {
  width: 100%;
  height: 45px;
  padding: 0 10px;
  font-size: 1rem;
  border-radius: 1px;
  font-family: 'TitilliumWeb-Regular';
}

#auth-wrapper input:not(:focus) {
  border: 1px solid #CCC;
  transition: all .2s ease-out 0s;
}

#auth-wrapper input:not(:focus)::placeholder {
  color: #CCC;
  transition: all .2s ease-out 0s;
}

#auth-wrapper input:focus::placeholder {
  color: #AAA;
  transition: all .2s ease-out 0s;
}

.form-action-bar {
  margin-bottom: 20px;
  margin-top: 20px;
}

#auth-wrapper input:focus {
  border: 1px solid #999;
  transition: all .2s ease-out 0s;
}


.btn-dropdown-transparent {
  background: transparent;
  border: none;
  box-shadow: none;
  /*font-family: 'TitilliumWeb-Bold';*/
}

.btn-dropdown-transparent:hover{
  text-decoration: none !important;
  border-bottom: none !important;
  cursor: pointer;
}

#user-dropdown #username {
  background: transparent;
  border: none;
  box-shadow: none;
  font-family: 'TitilliumWeb-Bold';
}

#user-dropdown #username:hover,
#user-dropdown:hover{
  text-decoration: none !important;
  border-bottom: none !important;
}
#user-dropdown .dropdown-menu {
  width: 100%;
  background-color: #FFF;
  box-shadow: 0 1px 3px 0 #BDBDBD;
  transition: all .1s ease-out 0s;
}
#user-dropdown .dropdown-menu a {
  color: #888;
}
#user-dropdown .dropdown-menu a:hover {
  color: #333;
}
#user-dropdown  .dropdown-item:active {
  color: #333;
  text-decoration: none;
  background-color: transparent;
}

#user-menu-wrapper {
  position: absolute;
  z-index: 999;
  right: 0;
  top: 5px;
  min-width: 200px;
  background-color: transparent;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: none;
  padding: 0 20px;
  transition: all .1s ease-out 0s;
}

#user-menu-wrapper:not(.opened) > #user-menu {
  height: 0;
  margin: 0;
  overflow: hidden;
  transition: all .1s ease-out 0s;
}

#user-menu-wrapper.opened {
  background-color: #FFF;
  box-shadow: 0 1px 3px 0 #BDBDBD;
  transition: all .1s ease-out 0s;
}

#user-menu li {
  margin: 10px 0;
  padding: 10px 5px;
}

#user-menu li a {
  color: #888;
}

#user-menu li:hover > a {
  color: #333;
}

#username {
  height: 45px;
  padding: 10px 0;
  color: #FFF;
  cursor: pointer;
  transition: all .1s ease-out 0s;
}

#user-menu-wrapper.opened > #username {
  padding: 15px 0;
  color: #333 !important;
  transition: all .2s ease-out 0s;
}

#username i {
  margin-left: 20px;
}

#page-header {
  font-size: 36px;
  background-color: #87e7ff;
  color: #200c41;
  font-weight: 400;
  font-family: 'TitilliumWeb-Regular';
  padding: 0 !important;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#evt-details-wrapper .main-header {
  justify-content: space-between;
  color: var(--header-bgColor);
  margin-bottom: 50px;
  padding: 0 10px;
}

#evt-details-wrapper .main-header .evt-link a {
  font-family: 'TitilliumWeb-SemiBold';
  font-size: 1rem;
  color: var(--header-bgColor);
}

#evt-details-wrapper .main-header .evt-name {
  font-family: 'TitilliumWeb-Regular';
  font-size: 2.5rem;
}

#evt-details-wrapper .avatar-wrapper {
  margin-right: 20px;
}

#evt-details-wrapper .avatar-wrapper img {
  max-width: 100px;
}

.tiles-wrapper {
  overflow: hidden;
  padding: 0;
}

.tiles-list {
  justify-content: space-between;
}

.tile {
  height: 300px;
  flex: 1;
  margin: 0 10px;
  padding: 20px;
  border-radius: 1px;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2);
  position: relative;
  flex-direction: column;
  justify-content: space-between;
}

.tile header {
  align-self: flex-start;
}

.tile header h1 {
  font-size: 2.5rem;
  font-family: 'TitilliumWeb-Regular';
  font-weight: 300;
}

.tile .action-bar {
  align-self: flex-end;
}

.tile.green {
  color: #212121;
  background-color: #4CAF50;
}

.tile.cyan {
  color: #212121;
  background-color: #26C6DA;
}

.tile.default {
  color: #FFF;
  background-color: var(--header-bgColor);
}

.tile.default .action-bar a {
  color: #FFF;
}

.tile.default .action-bar a:hover {
  border-bottom-color: #FFF;
}

.tips-wrapper {
  margin-top: 50px;
  padding: 10px 0;
}

.tips {
  margin: 0;
  padding: 10px;
}

.tips .tip {
  padding: 20px;
  background-color: #F5F5F5;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2);
}

.tip p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin-top: 10px;
  font-family: 'TitilliumWeb-Regular';
}

#hero {
  min-height: 450px;
  background: url('./../imgs/hero.png') center 100% no-repeat;
  background-size: cover;
}

#hero > .container {
  justify-content: flex-end;
}

#hero header {
  align-self: center;
  width: 30%;
  padding: 20px;
}

#hero header h3 {
  margin: 20px 0;
  line-height: 120%;
}

#vendez,
#agenda {
  background: #FAFAFA;
}

.pro-section header p {
  margin: 0;
  margin-top: 10px;
  font-size: 1.2rem;
  font-family: 'TitilliumWeb-Regular';
  color: #555;
}

#agenda ul.list {
  width: 300%;
  overflow: hidden;
  transform: translateX(0);
  transition: all 1s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0s;
}

#agenda ul.list > li {
  width: calc(100% / 3);
  display: inline-block;
  padding: 0 20px;
  float: left;
  display: flex;
  overflow: hidden;
}

#analysez .wrapper header,
#controllez .wrapper header,
#agenda ul.list > li header {
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-family: 'TitilliumWeb-SemiBold';
}
#analysez header h2,
#analysez .wrapper header {
  color: #FFF !important;
}

#analysez .wrapper p,
#controllez .wrapper p,
#agenda ul.list > li p {
  font-size: 1.1rem;
  line-height: 2.1rem;
  font-family: 'TitilliumWeb-Regular';
  color: #666;
}
#analysez header p,
#analysez .wrapper p {
  color: #333;
}

#agenda-bullets {
  text-align: center;
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: -45px;
}

#agenda-bullets span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #888;
  border-radius: 50%;
  margin: 0 3px;
  transition: all .2s ease-out 0s;
}

#agenda-bullets span.active {
  transform: scale(1.5);
  background: var(--btn-bgColor);
  transition: all .2s ease-out 0s;
}

#vendez .wrapper > div,
#analysez .wrapper > div,
#controllez .wrapper > div,
#communiquez .wrapper > div {
  padding: 0 20px;
}

#communiquez ul.list-wrapper {
  margin: 0;
}

#communiquez ul.list-wrapper li {
  margin: 30px 0;
}

#communiquez ul.list-wrapper li header h3 {
  font-family: 'TitilliumWeb-SemiBold';
}

#communiquez ul.list-wrapper li header > span {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #EEE;
  text-align: center;
  margin-right: 10px;
  padding-top: 2px;
}

#communiquez ul.list-wrapper li p {
  font-family: 'TitilliumWeb-Regular';
  font-size: 1rem;
  line-height: 1.5rem;
  color: #666;
  margin-top: 10px;
}

#vendez ul.list-wrapper > li {
  margin: 15px 5px;
  background: #FFF;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
  padding: 20px;
  overflow: hidden;
  cursor: pointer;
}

#vendez ul.list-wrapper > li header {
  position: relative;
}

#vendez ul.list-wrapper > li header i {
  font-weight: bold;
  transform: rotate(0);
  transition: all .2s linear 0s;
}

#vendez ul.list-wrapper > li p {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #666;
  font-family: 'TitilliumWeb-Regular';
  overflow: hidden;
  height: 0;
  margin: 0;
  transition: all .2s linear 0s;
}

#vendez ul.list-wrapper > li.active p {
  height: initial;
  margin: unset;
  margin-top: 10px;
  transition: all .2s linear 0s;
}

#vendez ul.list-wrapper > li.active header i {
  transform: rotate(180deg);
  transition: all .2s linear 0s;
}

#analysez {
  background: var(--btn-bgColor);
}

/* HOT FIXES */
#logo-whatsapp-wrapper,
#logo-facebookmessenger-wrapper {
  display: none;
}

#banner .carousel-inner .carousel-item.carousel-item-prev {
  transition-delay: 2s;
}

#ajust-icon {
  position: absolute;
  right: 25px;
}

/* added ?  */
#monprofil-content .wrapper,
#mesbillets-content .wrapper,
.menu-switcher {
  width: 50%;
}
.menu-switcher .btn.active {
  background-color: var(--btn-bgColor);
}
.menu-switcher .btn:not(.active):not(:hover) {
  background-color: var(--header-bgColor);
}
.menu-switcher .btn,
.menu-switcher .btn:hover {
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
  padding: 20px 0 !important;
}
.billet-details {
  margin-left: 15px;
  justify-content: flex-start;
  align-items: flex-start;
}
.billet-details > span {
  align-self: flex-start;
  margin-bottom: 10px;
}
.fixed-title {
  min-width: 120px;
}
.fixed-actionbar {
  max-width: 60px;
}

.custom-alert-danger {
   background: transparent;
   border: none;
   padding-top: 0;
   padding-left: 0;
   font-size: .8em;
   font-style: italic;
  color: #E53935;
}




