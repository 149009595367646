@media only screen and (max-width: 2560px) {
  #banner.for-booking header {
    padding: 30px 28%;
  }
}

@media only screen and (max-width: 1440px) {
  #banner.for-booking header {
    padding: 30px 10%;
  }
}

@media only screen and (max-width: 1024px) {
  #contact-content .wrapper {
    width: 60% !important;
  }
  footer .footer-col {
    padding: 0 20px !important;
  }
  #banner.for-booking header {
    padding: 30px 5%;
  }
  .breakpoints-list li.passed:not(:last-child):after,
  .breakpoints-list li:not(:first-child):before {
    width: 400%;
  }
}

@media only screen and (max-width: 768px) {
  .table input[disabled] {
    text-align: left !important;
  }
  .rp-xs {
    padding: 0;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .mosaic-like {
    flex-direction: column !important;
  }
  .mosaic-like > div {
    flex-basis: 100% !important;
    width: 100% !important;
  }
  .form-group-wrapper {
    padding: 0 10px !important;
  }
  #contact-content .wrapper {
    width: 80% !important;
  }
  #monprofil-content .wrapper,
  #mesbillets-content .wrapper,
  .menu-switcher {
    width: 90%;
  }
  #logo-whatsapp-wrapper,
  #logo-facebookmessenger-wrapper {
    display: block;
  }
  #reservation-wrapper {
    position: relative;
  }
  #event-details .row.flexible {
    padding-bottom: 0;
  }
  #hero > .container {
    justify-content: center;
  }
  #hero > .container > header {
    width: 50%;
  }
  #agenda ul.list > li img {
    width: 300px;
  }
  #vendez .wrapper,
  #analysez .wrapper,
  #communiquez .wrapper {
    flex-direction: column;
  }
  #vendez .wrapper img,
  #analysez .wrapper img,
  #communiquez .wrapper img {
    width: 100%;
  }
  #user-menu-wrapper {
    transform: translateX(-70%);
  }
  .main-header {
    flex-direction: column;
  }
  .main-header > div:first-child {
    align-self: flex-start;
  }
  .main-header > div:first-child .evt-name {
    font-size: 1.8rem !important;
  }
  .main-header > div:first-child .avatar-wrapper img {
    max-width: 80px !important;
  }
  .main-header > div:last-child {
    margin-top: 30px;
    align-self: flex-end;
  }
  .tiles-list {
    flex-direction: column;
  }
  .tiles-list .tile {
    width: 100%;
    margin-bottom: 30px;
  }
  .breakpoints-list li.passed:not(:last-child):after,
  .breakpoints-list li:not(:first-child):before {
    width: 300%;
  }

  #banner.for-booking .banner-img-wrapper img {
    transform: translateY(-5%);
  }

  header .logo-wrapper {
    /* position: static !important; */
  }

  header nav {
    /* position: relative; */
  }

  header #burger-menu {
    cursor: pointer;
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 8px;
    padding: 13px 0;
    text-align: center;
  }

  header nav ul {
    position: absolute;
    right: 0;
    top: 20px;
    margin: 0;
    z-index: -1;
    width: 200px;
    flex-direction: column;
    background-color: var(--header-bgColor);
    border-radius: 3px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .2);
    transform: translateY(50px);
    opacity: 0;
    transition: all .2s ease-out 0s;
  }

  header nav ul.open {
    transform: translateY(30px);
    z-index: 999;
    opacity: 1;
    transition: all .2s ease-out 0s;
  }

  header nav ul li {
    margin: 8px 0;
    padding: 5px 10px !important;
    width: 100%;
    text-align: right;
  }

  footer .flexible.main {
    flex-direction: column !important;
  }

  footer .footer-col {
    padding: 0 !important;
    margin: 30px 0;
    border: none !important;
  }

  #akces-rest,
  #solution-pro {
    text-align: left;
  }

  header#header .nav-wrapper {
    justify-content: flex-end;
  }

  footer .btn {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 600px) {
  form.mosaic {
    flex-direction: row !important;
    height: 190vh !important;
  }
  .form-group-wrapper {
    width: 100% !important;
  }
  .bo-form-group {
    padding: 0 !important;
  }
  .dispatch-children-xs > div {
    padding: 0 !important;
    margin-bottom: 20px !important;
  }
  #contact-content .wrapper {
    width: 90% !important;
  }
  footer .flexible.main {
    flex-direction: column !important;
  }

  footer .footer-col ul li {
    margin-right: 0;
  }

  header#header .nav-wrapper {
    justify-content: flex-end;
  }

  .sub-footer {
    padding-left: 20px !important;
  }
  #page-header h1 {
        font-size: 120% !important;
  }
    #mesbillets-content,
    #monprofil-content,
    .group {
        padding: 0 !important;
  }
    #mesbillets-content .wrapper,
    #monprofil-content .wrapper {
        width: 90% !important;
        margin: 20px 0 !important;
    }
    #action-bar {
        flex-direction: column !important;
    }
    #action-bar > a {
        font-size: 110% !important;
        margin-bottom: 20px !important;
    }
    .menu-switcher {
        width: 100% !important;
    }
    #mesbillets-content .table-row,
    #monprofil-content .table-row {
        margin-bottom: 20px;
        border-bottom: 1px solid #E0E0E0  ;
        flex-direction: column;
    }
    .monprofile-fieldinputs-feedback-wrapper {
        flex-direction: column !important;
        height: initial !important;
        border-left: none !important;
    }
    .monprofile-fieldinputs-feedback {
        align-self: flex-start !important;
        margin-bottom: 20px !important;
        display: inline-flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .monprofile-fieldinputs-feedback i {
        margin-right: 10px !important;
    }
    #mesbillets-content .table-row > div:first-child,
    #monprofil-content .table-row > div:first-child {
        flex-direction: column;
    }
    #mesbillets-content .table-row > div:first-child > div,
    #monprofil-content .table-row > div:first-child > div {
        width: 100% !important;
        border: none !important;
        align-self: flex-start !important;
        text-align: left !important;
    }
    #mesbillets-content .table-row > div:first-child > div > *,
    #monprofil-content .table-row > div:first-child > div > * {
        text-align: left !important;
    }
    #mesbillets-content .table-row > div:first-child > div input:not([type=checkbox]),
    #monprofil-content .table-row > div:first-child > div input:not([type=checkbox]) {
        padding: 20px !important;
    }
    #mesbillets-content .table-row .fixed-title,
    #monprofil-content .table-row .fixed-title {
        background: #F5F5F5  ;
    }
    .btn:not(.link-style) {
        width: 100% !important;
        font-size: 120% !important;
        border: none !important;
    }
    .rp-xs {
        padding: 0 !important;
    }
    .fit-xs {
        width: 100% !important;
    }
    .displayLinks {
        padding: 0 20px !important;
    }
    .submit-button-wrapper {
        margin: 20px 0 !important;
    }
    .flex-column-xs {
        flex-direction: column !important;
    }
    .paddings {
        padding: 30px 0 !important;
    }
    .datatable-row-wrapper {
        padding: 20px 0;
        border-bottom: 1px solid #E0E0E0  ;
    }
    .datatable-row-wrapper:nth-child(2n+1) {
        background: #F5F5F5  ;
    }
    .datatable-header {
        height: initial !important;
    }
    .datatable-row-center {
        display: flex;
        flex-direction: column;
    }
    .datatable-header-cell,
    .datatable-body-cell {
        width: 100% !important;
    }
    .mb-20-xs {
        margin-bottom: 20px !important;
    }
    #access-desc h3 {
        font-size: 110%;
        line-height: 160%;
    }
    #access-desc {
        left: 0 !important;
        right: 0 !important;
        top: unset !important;
        width: 100% !important;
        height: initial !important;
    }
    .counter-wrapper ul li input {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 500px) {
  .column-150 > .img-wrapper {
    min-width: 100px;
    max-width: 150px;
  }
  #monprofil-content .wrapper,
  #mesbillets-content .wrapper,
  .menu-switcher {
    width: 100%;
  }
  #hero > .container > header {
    width: 100%;
  }
  #agenda ul.list > li {
    display: flex;
    flex-direction: column-reverse;
  }
  #agenda ul.list > li > div:last-child {
    margin-bottom: 50px;
  }
  #vendez .wrapper img {
    width: 100%;
  }
  #analysez .wrapper img,
  #controllez .wrapper img {
    width: 100%;
  }
  #controllez .wrapper {
    flex-direction: column-reverse;
  }
  #user-menu-wrapper {
    transform: translateX(-10%);
  }
  #user-menu-wrapper.opened > #username {
    padding: 10px 0;
  }
  #username span {
    display: none;
  }
  #username {
    height: 40px;
    width: 40px;
    background-color: var(--btn-bgColor);
    border-radius: 50%;
    text-align: center;
  }
  #username i {
    font-weight: bold;
    margin: 0;
    font-size: 1.4rem;
  }
  .table-row > div:last-child {
    flex-direction: column;
    border-left: 1px solid #E0E0E0;
  }

  .table-row > div:first-child {
    border-right: 0;
  }

  .table-row > div:last-child > div {
    width: 100%;
  }

  .table-row > div:last-child > div {
    flex-direction: column;
  }

  #monprofil-content .table-row > div:last-child > div,
  #mesbillets-content .table-row > div:last-child > div {
    flex-direction: row;
  }

  .table-row > div:last-child > div > * {
    align-self: flex-start;
  }

  .table-row > div:last-child .column:not(:last-child) {
    padding: 10px 20px;
    border-bottom: 1px dashed #E0E0E0;
  }

  .breakpoints-list li label {
    font-size: 1rem;
  }

  .breakpoints-list {
    flex-direction: row;
  }

  .breakpoints-list li {
    margin: 0px;
  }
  .breakpoints-list li:first-child {
    margin-top: 0;
  }
  .breakpoints-list li:last-child {
    margin-bottom: 0;
  }
  .breakpoints-list li a{
    padding-top : 16px;
  }

  .breakpoints-list li.passed:not(:last-child):after,
  .breakpoints-list li:not(:first-child):before {
    width: 50%;
    height: 5px;
    margin-top: -50%;
    margin-left: 50%;
  }
  .breakpoints-list li:not(:last-child):after {
    width: 5px;
    height: 0;
    top: 92px;
    left: -32.5px;
  }
  .breakpoints-list li:not(:first-child):before {
    left: 0;
    height: 5px;
    top: 50%;
    margin-top: 5px;
    transform: translateX(27.5px) translateY(-100%);
  }
  .breakpoints-list li:last-child:before {
    width : 0px;
    height : 0px;
  }
  .breakpoints-list li label {
    transform: translateX(-120%) translateY(-40px);
    text-align: right;
    width: initial;
    display: none;
  }

  .travel {
    margin: 0;
  }

  #banner.for-booking .banner-img-wrapper img {
    transform: translateY(0);
    height: 300px;
  }

  .continue {
    text-align: center !important;
  }

  ul.list.flexible {
    flex-direction: column !important;
  }

  ul.list.flexible > li {
    padding: 0;
    margin: 30px 0;
  }

  footer .footer-col {
    padding: 0 10px !important;
    margin: 30px 0;
    border: none !important;
  }

  .row {
    margin: 0;
    padding: 0;
  }

  #discover-events .section-content > .row {
    justify-content: center;
  }
}

@media only screen and (max-width: 400px) {
  .menu-switcher .btn {
    min-width: unset;
  }
  #event-details .col-xs-12 {
    padding: 0 !important;
  }
  .user-card {
    flex-direction: column;
  }
  .card-mini .card-body .social-nets li {
    width: 40px;
    height: 40px;
  }
  .user-card header {
    margin: 0;
    margin-top: 20px;
    text-align: center;
  }
  .counter-wrapper {
    padding: 50px 0px;
  }
  .sub-footer ul li {
    display: block !important;
    margin: 10px 0 !important;
  }

  ul.actions {
    margin: 10px 0 !important;
  }

  ul.list {
    margin: 0;
  }

  .evt-actionbar {
    margin-top: 30px;
  }

  button.btn {
    font-size: .8rem;
    min-width: unset;
    padding-left: 20px;
    padding-right: 20px;
  }

  .evt-infos {
    font-size: .7rem;
  }
}
