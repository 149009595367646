.fab-btn{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .4);
  margin: 0 5px;
  font-size: 1.3rem;
  cursor: pointer;
}
.button-container{
  height : 50px;
}